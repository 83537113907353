<template>
	<aside class="quiz__header">
		<v-container>
			<v-row class="align-center">
				<v-col cols="12">
					<h2 class="text-center quiz__header--title">
						{{ quizName }}
					</h2>
					<div class="progress__wrapper primary-contrast-background">
						<v-progress-linear
							v-model="quizProgress"
							color="blue"
							height="5"
						></v-progress-linear>
						<span class="progress__wrapper--step"
							>{{ activeQuestion + 1 }} of {{ steps }}</span
						>
					</div>
					<p class="text-center quiz__header--subtitle">
						{{ startedHeading }}
					</p>
				</v-col>
			</v-row>
		</v-container>
	</aside>
</template>

<script>
export default {
	name: "QuizHeader",
	props: {
		quiz: {
			type: Object,
			require: true
		}
	},

	computed: {
		steps() {
			return this.quiz?.questions.length;
		},
		activeQuestion() {
			const question = Number(this.$route.query.question);
			return this.quiz?.questions.findIndex(
				el => el.question_id === question
			);
		},
		quizProgress() {
			const step = this.activeQuestion + 1;
			const steps = this.steps;
			return Number((step / steps) * 100);
		},
		quizName() {
			if (!!this.quiz === false) return;
			return this.quiz?.name;
		},
		startedHeading() {
			if (!!this.quiz === false) return;
			return this.quiz?.started_heading;
		}
	}
};
</script>

